import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import AuthService from 'src/app/data/services/auth/auth.service';

/** Inject Token in to the request header */
@Injectable()
export default class TokenAuthorizationRequestInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      setHeaders: {
        Authorization: `Token ${this.auth.getToken()}`,
      },
    });
    return next.handle(request);
  }
}
