  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">

    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-5 col-md-12 footer-info">
          <a href="index.html" class="logo d-flex align-items-center">
            <span>groWiser</span>
          </a>
          <p>groWiser financial service has a wide range of financial services to cater to the varied requirements of customers.</p>
          <div class="social-links d-flex mt-4">
            <a href="https://www.instagram.com/growiser_official/" class="twitter"><i class="bi bi-twitter"></i></a>
            <a href="https://www.instagram.com/growiser_official/" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/growiser_official/" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="https://www.instagram.com/growiser_official/" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 text-md-start">
          <h2>Contact Us</h2>
          <div>
            <span style="font-weight: bold; background-color:aliceblue;color: #f97300;">Raipur Address: </span><span>703-705,B-wing, Babylon Tower, VIP chowk
              Raipur(C.G.) Pin 492001</span>
          </div>
          <div>
            <span style="font-weight: bold;background-color:aliceblue;color: #f97300;">Korba Address: </span><span>Ved Bhawan, Main road chhuri, Near Boays zone, Korba Pin: 495450</span>
          </div>
          
        </div>

        <div class="col-lg-3 col-md-12 footer-contact text-md-start">
          <h2 class="desktop-only"> &nbsp;</h2>
          <div>
            <span style="font-weight: bold;background-color:aliceblue; color: #f97300;">Bilaspur Address: </span><span>Near Amba Tower, Vinoba Nagar
              Bilaspur chhattisgarh 495001</span>
          </div>
          <div>
            <span style="font-weight: bold;background-color:aliceblue;color: #f97300;">Bhopal Address: </span><span>11/9, Jharneshwer complex
              Jawahar chaowk, Bhopal</span>
          </div>

        </div>

      </div>
    </div>

    <div class="container mt-4">
      <div class="copyright">
        &copy; Copyright <strong><span>groWiser</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/impact-bootstrap-business-website-template/ -->
        <!-- Designed by <a href="http://a19infoways.com">a19 Infoways</a> -->
      </div>
    </div>

  </footer><!-- End Footer -->
  <!-- End Footer -->
