<div class="bg-blk">
    <div class="">
        <div class="">
            <div class="container">
                <div class="topnav" id="myTopnav">
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="margin-t-50">
                                <a href="/" class="d-none-mob">
                                </a>

                                <a href="/" class="d-none-mob">
                                    <i class="fa fa-home icon-extra-small"></i>
                                    Home
                                </a>

                                <a href="/aboutus">
                                    <i class="fa fa-indent icon-extra-small"></i>
                                    About
                                </a>

                                <div class="dropdown">
                                    <button class="dropbtn">
                                        <i class="fa  fa-sun-o icon-extra-small"></i>
                                        Industries DDL
                                        <i class="fa fa-caret-down"></i>
                                    </button>

                                    <div class="dropdown-content">
                                        <a href="/">
                                            <i class="fa fa-laptop" aria-hidden="true"></i>
                                            Sub Menu
                                        </a>
                                        <a href="/aboutus">
                                            <i class="fa fa-cog" aria-hidden="true"></i>
                                            Sub Menu 2
                                        </a>
                                        <a href="/">
                                            <i class="fa fa-cube" aria-hidden="true"></i>
                                            Sub Menu 3
                                        </a>

                                    </div>
                                </div>

                                <a href="javascript:void(0);" style="font-size:15px;" class="icon"
                                    onclick="myFunction()">&#9776;</a>
                            </div>
                            <!--<router-outlet></router-outlet> ---->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>