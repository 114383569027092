import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import AuthService from 'src/app/data/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export default class UserTypeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isUserAuthenticated()) {
      this.router.navigateByUrl('/');
      return false;
    }
    const { data } = route;
    const userRole = localStorage.getItem('role');
    if (userRole && data && data.userType) {
      const expectedUserTypes = data.userType;
      const canActivate = expectedUserTypes.some((userType: string) => userType === userRole);

      if (!canActivate) {
        this.router.navigate(['/dashboard']);
      }
      return canActivate;
    }
    this.router.navigate(['/dashboard']);
    return true;
  }
}
