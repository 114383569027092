import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import AuthGuard from './guard/auth.guard';
import UserTypeGuard from './guard/user-type.guard';
import TokenAuthorizationRequestInterceptor from './interceptor/token-authorization.interceptor';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, BrowserModule],
  exports: [],
  providers: [
    AuthGuard,
    UserTypeGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenAuthorizationRequestInterceptor, multi: true },
  ],
})
export default class CoreModule {}
