import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { tap } from 'rxjs/operators';
import environment from 'src/environments/environment';
import { ForgetPassword, User, UserCredentials } from '../../schema/user';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  isUserLoggedIn = new BehaviorSubject<boolean>(false);

  currentUser: Subject<User> = new BehaviorSubject<User>({});

  constructor(private http: HttpClient) {}

  isUserAuthenticated(): boolean {
    return this.isUserLoggedIn.value;
  }

  // eslint-disable-next-line class-methods-use-this
  getToken(): string {
    return localStorage.getItem('token')!;
  }

  login(credentials: UserCredentials): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}sessions`, credentials).pipe(
      tap((data) => {
        localStorage.setItem('userId', data.user_id);
        localStorage.setItem('token', data.token);
        this.isUserLoggedIn.next(true);
        return data;
      })
    );
  }

  isAuthenticated(): Observable<any> {
    return this.http.put<any>(`${environment.API_URL}sessions/ping`, {});
  }

  logout(): Observable<any> {
    return this.http.delete<any>(`${environment.API_URL}sessions`).pipe(
      tap(() => {
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        this.currentUser.next({});
        this.isUserLoggedIn.next(false);
      })
    );
  }

  forgetPassword = (reset: ForgetPassword) => {
    return this.http.post(`${environment.API_URL}users/lost_password`, reset);
  };
}
