import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import AuthService from 'src/app/data/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export default class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (!this.authService.isUserAuthenticated()) {
      this.router.navigateByUrl('/');
      return false;
    }
    return true;
  }
}
